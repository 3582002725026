import React from "react";
import { bannerItems } from "./actionItems";

function ActionBanner() {
  return (
    <div className="text-center w-full mx-auto my-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20 flex-row space-y-4 ">
      {bannerItems.map((banner) => {
        return (
          <div
            key={banner.key}
            className="flex-row md:flex mx-auto w-full  md:w-1/2 justify-between  space-x-4 space-y-4 md:space-y-0"
          >
            <div className="flex justify-center  md:w-1/4  items-center text-4xl ml text-white ">
              {banner.icon()}
            </div>
            <div className="flex-row  md:w-1/2 space-y-2">
              <h1 className="text-xl text-center font-bold text-white">
                {banner.tag}
              </h1>
              <h2 className="text-base text-white">{banner.descripton}</h2>
            </div>
            <div className="flex justify-center  md:w-1/4 items-center ">
              <div
                onClick={banner.action}
                className="md:absolute px-4 py-2 font-bold rounded-full cursor-pointer bg-purple-600 text-white hover:bg-purple-500"
              >
                {banner.actionTag}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ActionBanner;
