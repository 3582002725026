import * as React from "react";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Thanks from "../../components/thanks";
import SignUpMessage from "../../components/SignUpMessage";


const ThanksPage = () => (
  <Layout>
    <Seo title="Thank You!" />
    {/* <Thanks /> */}
    <SignUpMessage />
  </Layout>
);

export default ThanksPage;
