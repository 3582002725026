import React from "react";
import { navigate } from "gatsby";
import { AiOutlineUnlock } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import { SiTinyletter } from "react-icons/si";

export const bannerItems = [
  {
    key: "Survey",
    icon: () => <AiOutlineUnlock />,
    tag: "Get Access Sooner",
    descripton:
      "We'd love to learn how you learn. Your input will help accelerate your learning with QuizMe Ai, and we'll bump you to the front of the line as a thank you!",
    actionTag: "Learning Survey",
    action: () => navigate("https://x4g14op9hp6.typeform.com/to/BMtgsaAS"),
  },
  {
    key: "Twitter",
    icon: () => <FiTwitter />,
    tag: "Stay Updated",
    descripton:
      "Be the first to know about new releases, and get sneak peaks before anyone else.",
    actionTag: "Follow @QuizMeAI",
    action: () => navigate("https://twitter.com/quizmeai"),
  },
  {
    key: "Mission",
    icon: () => <SiTinyletter />,
    tag: "Read Our Mission",
    descripton:
      "We are on mission to accelerate human learning.",
    actionTag: "Our Mission",
    action: () => navigate("https://quizme.ai/mission"),
  },
];
