import React from "react";
import { RiProductHuntLine } from "react-icons/ri";
import ActionBanner from "./ActionBanner/ActionBanner";

function SignUpMessage() {
  return (
    <div
      className="w-full py-8"
      style={{
        background:
          "radial-gradient(130.73% 428.55% at 0% 0%, #20b7d4 0%, #F34889 53.65%, #6459F2 100%)",
      }}
    >
      <div className="flex-row pb-8 my-8  mx-auto space-y-8">
        <h1 className="text-4xl font-bold text-center text-white">
          You're on the list
        </h1>
        <h4 className="text-xl text-center w-1/2 mx-auto text-white">
          We are working really hard to accelerate your learning. We'll inform
          you as soon as we're ready for you.
        </h4>
        {/* <div className="flex items-center justify-center w-56 h-16 p-4 bg-pink-600 mx-auto rounded hover:bg-pink-700 cursor-pointer">
          <div className="text-2xl flex items-center space-x-4">
            <div className="text-white text-4xl">
              <RiProductHuntLine />
            </div>
            <div className="flex-row ">
              <h4 className="text-xs text-white">Launching on</h4>
              <h2 className="text-base font-bold text-white">Product Hunt</h2>
            </div>
          </div>
        </div> */}
        <div className="flex">
          <ActionBanner />
        </div>
      </div>
    </div>
  );
}

export default SignUpMessage;
